import { Image } from "./image";
import React from "react";

export const Government = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>What makes us stand out</h2>
          <p>
          We are connecting millions of systems together on Verificare through our APIs, with the primary aim of enabling easier information inter-change between service provider and client. 
          </p>
        </div>
      </div>
    </div>
  );
};
